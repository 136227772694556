<template>
    <div class="home">
        <nav-bar />
        <hero />
        <separator-white />
        <quienes-somos />
        <separator-pink />
        <candidatos />
        <separator-red />
        <empresas />
        <separator-yellow />
        <linkedin />
        <separator-white />
        <legal-footer />
    </div>
</template>

<script>
import SeparatorWhite from '@/components/SeparatorWhite'
import SeparatorPink from '@/components/SeparatorPink'
import SeparatorRed from '@/components/SeparatorRed'
import SeparatorYellow from '@/components/SeparatorYellow'
import NavBar from '@/components/NavBar'
import Hero from '@/components/Hero.vue'
import QuienesSomos from '@/components/QuienesSomos.vue'
import Candidatos from '@/components/Candidatos.vue'
import Empresas from '@/components/Empresas.vue'
import Linkedin from '@/components/Linkedin'
import LegalFooter from '@/components/LegalFooter'

export default {
    name: 'Home',
    components: {
        SeparatorWhite,
        SeparatorPink,
        SeparatorRed,
        SeparatorYellow,
        NavBar,
        Hero,
        QuienesSomos,
        Candidatos,
        Empresas,
        Linkedin,
        LegalFooter,
    }
}
</script>