<template>
  <div id="candidatos" class="section bg-pink">
    <div class="w-100">
      <b-container class="section-inner-wide text-left">
        <div class="text-lg ArsenicaBold black mt-0 mb-0">Candidatos</div>
        <div class="text-md text-left MontserratRegular black mt-0 mb-4">
          que llegan a buen puerto
        </div>
        <div class="MontserratMedium black text-left mb-4">
          ¿En busca de nuevos retos? ¡Descubre las ofertas de empleo que tenemos
          en marcha!
        </div>
        <iframe
          id="iframe"
          src="https://jobs.somosbuenpuerto.com"
          style="border: 0px #ffffff none"
          name="myiFrame"
          scrolling="no"
          frameborder="1"
          marginheight="0px"
          marginwidth="0px"
          height="400px"
          width="600px"
          allowfullscreen
        ></iframe>
        <!-- <div class="job-container">
          <job v-for="(job, index) in Jobs.jobs" :key="index" :job="job"></job>
        </div>
        <div class="MontserratMedium black my-4">
          <p>
            ¿No encuentras un puesto adecuado para ti? Escríbenos, ¡queremos
            conocerte!
          </p>
        </div>
        <div class="d-flex justify-content-center">
          <a
            class="btn"
            href="https://jobs.somosbuenpuerto.com/jobs/autocandidatura-ea08f39a-a170-41e4-b60f-1d126118f39b"
            target="_blank"
          >
            Envíanos tu currículum
          </a>
        </div> -->
        <!-- <b-form @submit.prevent="submit" method="post">
              <b-form-row>
                <b-col>
                  <b-form-group>
                    <b-form-input
                      class=""
                      v-model="form.first_name"
                      :state="validateState('first_name')"
                      type="text"
                      autocomplete="off"
                      placeholder="Nombre*"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback>
                      Se te ha olvidado darnos tu nombre
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <b-form-input
                      class=""
                      v-model="form.last_name"
                      :state="validateState('last_name')"
                      type="text"
                      autocomplete="off"
                      placeholder="Apellidos*"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback>
                      Se te ha olvidado darnos tus apellidos
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-form-row>
              <b-form-row>
                <b-col>
                  <b-form-group>
                    <b-form-input
                      class=""
                      v-model="form.email"
                      :state="validateState('email')"
                      type="email"
                      autocomplete="off"
                      placeholder="Email*"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback>
                      Necesitamos un email válido
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <b-form-input
                      v-model="form.phone"
                      :state="validateState('phone')"
                      type="text"
                      autocomplete="off"
                      placeholder="Teléfono*"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback>
                      Se te ha olvidado darnos tu teléfono
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-form-row>
              <b-form-row>
                <b-col>
                  <b-form-group>
                    <b-form-select
                      v-model="form.area"
                      :options="options"
                      :state="validateState('area')"
                      placeholder="Sector*"
                    ></b-form-select>
                    </b-form-input>
                    <b-form-invalid-feedback>
                      Se te ha olvidado decirnos cuál es tu sector
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                  <b-form-file
                    accept="application/pdf,application/vnd.ms-excel"
                    v-model="form.file"
                    :state="validateState('file')"
                    placeholder="CV*"
                    drop-placeholder="Arrastra tu archivo aquí..."
                  ></b-form-file>
                    <b-form-invalid-feedback>
                      Se te ha olvidado adjuntar tu CV
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-form-row>
              <b-form-row>
                <b-col>
                  <b-form-group>
                    <b-form-input
                      class=""
                      v-model="form.site"
                      type="text"
                      autocomplete="off"
                      placeholder="Web o portfolio"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <b-form-input
                      v-model="form.linkedin"
                      type="text"
                      autocomplete="off"
                      placeholder="Perfil de LinkedIn"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>
              <b-form-group>
                <b-form-textarea
                  v-model="form.comment"
                  rows="3"
                  placeholder="¡Cuéntanos qué estás buscando!"
                >
                </b-form-textarea>
              </b-form-group>
              <p class="black small">*Campos obligatorios</p>
              <b-form-group>
                  <b-form-checkbox
                      v-model="form.accept"
                      name="checkbox-1"
                      :value="true"
                      :unchecked-value="false">
                    <p class="black xsmall">
                      Tus datos serán tratados por serán tratados por SOMOS BUEN PUERTO con la
finalidad de gestionar tu perfil laboral y ponerte en contacto con empresas que estén
buscando cubrir vacantes que puedan ser adecuadas para ti. La base de legitimación
para efectuar este tratamiento será el propio consentimiento que nos otorgas al enviar
el presente formulario. No cederemos tus datos a terceros sin tu consentimiento, salvo
obligación legal. Puedes ejercitar tus derechos de acceso, rectificación, supresión,
portabilidad y la limitación u oposición al tratamiento, mediante el envío de un correo
electrónico a
                      <a class="black bold" href="mailto:lopd@somosbuenpuerto.com"
                        >lopd@somosbuenpuerto.com</a
                      >. Información adicional sobre el tratamiento de tus datos
                      <a
                        class="black bold pointer"
                        @click="$bvModal.show('modal-legal-candidatos')"
                        >aquí</a
                      >.
                    </p>
                  <b-form-invalid-feedback :state="validateState('accept')" class="black">
                      Tienes que aceptar el consentimiento
                  </b-form-invalid-feedback>
                  </b-form-checkbox>
              </b-form-group>
              <div class="d-flex justify-content-center mt-4">
                <b-button type="submit" :disabled="sending" class="btn-black">
                  {{ sending ? "ENVIANDO..." : "ENVIAR" }}
                </b-button>
              </div>
            </b-form> -->
      </b-container>
    </div>
    <img id="isla" src="../assets/img/isla.gif" alt="" />
    <b-modal
      id="modal-applicant"
      title="BootstrapVue"
      hide-header
      hide-footer
      centered
    >
      <div class="m-8">
        <div class="my-4">
          <p class="my-4 text-center MontserratBold mb-0">
            Tu solicitud a llegado a Buenpuerto.
          </p>
          <p class="text-center mt-0">
            ¡En breve nos pondremos en contacto contigo!
          </p>
        </div>
        <div class="m-4 text-center">
          <b-button @click="$bvModal.hide('modal-applicant')"
            >De acuerdo</b-button
          >
        </div>
      </div>
    </b-modal>
    <modal-legal-candidatos></modal-legal-candidatos>
  </div>
</template>

<script>
import Job from "@/components/Job";
import ModalLegalCandidatos from "@/components/ModalLegalCandidatos";
import requestApi from "@/api/request.js";
import { validationMixin } from "vuelidate";
import { required, minLength, email, sameAs } from "vuelidate/lib/validators";
import Jobs from "../data/jobs.json";

export default {
  mixins: [validationMixin],
  name: "Candidatos",
  components: {
    Job,
    ModalLegalCandidatos,
  },
  mounted() {
    this.getJobs();
    this.renderJobs();
  },
  data() {
    return {
      Jobs,
      data: [],
      cities: [],
      jobs: [],
      options: [
        { value: null, text: "Sector*" },
        { value: "a", text: "This is First option" },
        { value: "b", text: "Selected Option" },
        { value: "c", text: "This one is disabled", disabled: true },
      ],
      form: {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        area: null,
        cv: null,
        site: null,
        linkedin: null,
        comment: null,
        accept: false,
      },
      sending: false,
      success: false,
    };
  },
  validations: {
    form: {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      email: {
        required,
        email,
      },
      phone: {
        required,
        minLength: minLength(9),
      },
      area: {
        required,
      },
      file: {
        required,
      },
      accept: {
        sameAs: sameAs(() => true),
      },
    },
  },
  methods: {
    async getJobs() {
      axios
        .post("https://jobs.somosbuenpuerto.com/jobs/search")
        .then(function (response) {
          console.log("response", response);
        })
        .catch(function (error) {
          console.log("error", error);
        });
    },

    renderJobs() {
      console.log("jobs", this.Jobs.jobs.length);
    },

    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        area: null,
        file: null,
        site: null,
        linkedin: null,
        comment: null,
        accept: false,
      };
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    async submit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) return;

      this.sending = true;

      const data = new FormData();

      if (this.form.file) data.append("file", this.form.file);

      const json = JSON.stringify({
        first_name: this.form.first_name,
        last_name: this.form.last_name,
        email: this.form.email,
        phone: this.form.phone,
        area: this.form.area,
        site: this.form.site,
        linkedin: this.form.linkedin,
        comment: this.form.comment,
      });

      data.append("data", json);

      try {
        await requestApi.applicantRequest(data);
        this.sending = false;
        this.success = true;
        this.resetForm();
        this.$bvModal.show("modal-applicant");
      } catch (err) {}
    },
  },
};
</script>