<template>
    <div class="separator-yellow">
        <div></div>
    </div>
</template>
<script>
export default {
    name: 'SeparatorYellow',
    components: {},
    data() {
        return {
        }
    },
    computed: {},
    methods: {}
}
</script>