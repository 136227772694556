<template>
  <div>
    <b-navbar toggleable="lg" type="dark">
      <b-navbar-brand href="#">
        <a href="#hero" v-smooth-scroll>
          <img src="../assets/svg/buenpuerto-logo.svg" width="240" />
        </a>
      </b-navbar-brand>
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-item class="MontserratBold hide-md">
          <a class="black" href="#quienessomos" v-smooth-scroll
            >Quiénes somos</a
          >
        </b-nav-item>
        <b-nav-item class="MontserratBold hide-md">
          <a class="black" href="#candidatos" v-smooth-scroll>Candidatos</a>
        </b-nav-item>
        <b-nav-item class="MontserratBold hide-md">
          <a class="black" href="#empresas" v-smooth-scroll>Empresas</a>
        </b-nav-item>
        <div class="hide-md">
          <a
            class="black pointer"
            href="https://www.linkedin.com/company/buenpuerto/?viewAsMember=true"
            target="_blank"
          >
            <img src="../assets/svg/icon-linkedin.svg" width="24" />
          </a>
        </div>
        <b-nav-item class="show-md">
          <a class="black" @click="toggleMenu">
            <img src="../assets/svg/icon-hamburger.svg" width="24" />
          </a>
        </b-nav-item>
      </b-navbar-nav>
    </b-navbar>
    <div v-if="showMenu" class="menu-mobile">
      <b-navbar>
        <!-- Right aligned nav items -->
        <b-navbar-nav>
          <b-nav-item class="w-100 text-right">
            <a class="black" @click="toggleMenu">
              <img src="../assets/svg/icon-close.svg" width="24" />
            </a>
          </b-nav-item>
        </b-navbar-nav>
      </b-navbar>
      <div class="section text-center">
        <div class="menu">
          <div class="MontserratBold">
            <a
              class="black"
              href="#quienessomos"
              v-smooth-scroll
              @click="showMenu = false"
              >Quiénes somos</a
            >
          </div>
          <div class="MontserratBold black">
            <a
              class="black"
              href="#candidatos"
              v-smooth-scroll
              @click="showMenu = false"
              >Candidatos</a
            >
          </div>
          <div class="MontserratBold black">
            <a
              class="black"
              href="#empresas"
              v-smooth-scroll
              @click="showMenu = false"
              >Empresas</a
            >
          </div>
          <div>
            <a
              class="black"
              href="https://www.linkedin.com/company/buenpuerto/?viewAsMember=true"
              target="_blank"
              v-smooth-scroll
              @click="showMenu = false"
            >
              <img src="../assets/svg/icon-linkedin.svg" width="40" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "nav-bar",
  components: {},
  data() {
    return {
      showMenu: false,
    };
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
  },
};
</script>