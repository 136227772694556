<template>
    <div class="job-wrapper" @click="navigate">
        <img class="job-header" src="https://assets.bizneo.com/system/images/files/021/290/363/original/imagen_ofertas.png?1666712470" alt="logo"></img>
        <div class="job">
            <div class="job-category">{{ job.category.replaceAll('_',' ') }}</div>
            <div class="job-title">{{ job.title }}</div>
            <div class="job-location">{{ job.regions[0].translations.default }}</div>
            <div class="job-aggregation">{{ job.aggregation.name }}</div>
            <div class="job-cta">Apply</div>
        </div>
    </div>
</template>

<script>
export default {
  name: "Job",
  components: {},
  props: {
    job: {},
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    navigate() {
        window.open(`https://jobs.somosbuenpuerto.com/jobs/${this.job.slug}`);
    }
  },
};
</script>