
export default {
    applicantRequest(form) {
        return axios.post('/applicant/application.php', form)
    },
    companyRequest(form) {
        return axios.post('/company/application.php', form)
    },
    companyRequestConfirmationMail(form) {
        return axios.post('/company/confirmation.php', form)
    },
    companyRequestNotificationMail(form) {
        return axios.post('/company/notification.php', form)
    }
}
