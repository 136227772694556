<template>
    <div id="quienessomos" class="section bg-white d-flex flex-column px-4 text-left">
    	<div class="flex-grow-1 d-flex align-items-center justify-content-center">
    		<b-container class="section-inner-wide">
                    <div class="text-lg text-left ArsenicaBold black mt-0 mb-4">
                        ¿Quiénes somos?
                    </div>
                    <div class="MontserratMedium black mb-16">
                        <p>Es curioso, todos queremos llegar al mismo lugar.</p>
                        <p>Sí, seguro que has escuchado hablar de él, aunque a priori te parezca un espejismo. Ese lugar se llama Buenpuerto y, si estás leyendo esto, puede que cada vez estés más cerca de él.</p>
                        <p>Somos una compañía especializada en la búsqueda de talento para empresas de todo tipo. Aquí compañías y Candidatos se conocen, conectan, y emprenden un viaje juntos gracias a un proceso de selección estratégico, cuidado y eficiente tanto para el Candidato como para la compañía.</p>
                        <p>Si tú también estás en busca de nuevas aventuras o nuevos perfiles, habla con nosotros.</p>
                    </div>
                    <div class="d-flex justify-content-center">
                        <a class="btn" href="https://jobs.somosbuenpuerto.com/jobs/autocandidatura-ea08f39a-a170-41e4-b60f-1d126118f39b" target="_blank">
                            Quiero llegar a buen puerto
                        </a>
                    </div>
    		</b-container>
    	</div>
        <img id="faro" src="../assets/img/faro.gif" alt="">
    </div>
</template>

<script>

export default {
    name: 'quienessomos',
    components: {
    }
}
</script>