<template>
  <b-modal id="modal-legal-empresas" size="lg" hide-header hide-footer centered>
    <div class="m-8">
      <div class="my-4">
        <p class="my-4 text-sm text-center MontserratBold mb-0">
          INFORMACIÓN COMPLETA SOBRE PROTECCIÓN DE DATOS
        </p>
        <div>
          <table class="text-xs">
            <tr>
              <td>Responsable</td>
              <td>
                <strong>BUEN PUERTO RH, S.L.</strong><br />Altos del club de
                polo, 32. 28692 Villanueva de la Cañada (Madrid)<br /><strong
                  >B72488927</strong
                ><br /><a href="mailto:lopd@somosbuenpuerto.com"
                  >lopd@somosbuenpuerto.com</a
                >
              </td>
            </tr>
            <tr>
              <td>Finalidad</td>
              <td>
                En SOMOS BUEN PUERTO, trataremos la información facilitada para
                contactarte y valorar y en su caso, formalizar una posible
                colaboración comercial entre ambas partes.
              </td>
            </tr>
            <tr>
              <td>Legitimación y conservación</td>
              <td>
                La base de legitimación para el tratamiento de los datos es el
                consentimiento otorgado mediante el envío del propio formulario.
                Si una vez que nos hayamos puesto en contacto, formalizamos una
                relación comercial, trataremos tus datos en tanto en cuanto
                dicha relación siga vigente. Sin embargo, si finalmente no
                conseguimos cerrar una colaboración entre ambas partes tus datos
                serán eliminados en un plazo de un (1) mes a contar desde que se
                realizó el contacto entre las partes.
              </td>
            </tr>
            <tr>
              <td>Cesiones</td>
              <td>
                Tus datos no serán cedidos a terceros, salvo obligación legal,
                aunque obviamente y si formalizamos una relación, podremos
                comunicar algunos de tus datos a aquellos candidatos que estén
                buscando oportunidades laborales.
              </td>
            </tr>
            <tr>
              <td>Derechos</td>
              <td>
                Tienes derecho a acceder a tus datos personales, así como a
                solicitar la rectificación de datos inexactos o, en su caso,
                solicitar la supresión de los mismos cuando, entre otros
                motivos, los datos ya no sean necesarios para los fines que
                fueron recogidos. Podrás igualmente solicitar la limitación del
                tratamiento de sus datos, en cuyo caso, únicamente los
                conservaremos para el ejercicio o la defensa de reclamaciones.
                Tienes derecho a reclamar ante la Autoridad de Control. Tienes
                derecho a solicitar la portabilidad de sus datos, así como a
                limitar u oponerse al tratamiento inicialmente consentido,
                retirando en cualquier momento el consentimiento prestado y
                ejercer su derecho al olvido. Puedes ejercer sus derechos,
                dirigiendo comunicación escrita, acompañada de fotocopia del DNI
                o documento acreditativo de su identidad al domicilio social de
                SOMOS BUEN PUERTO antes indicado o mediante correo electrónico
                a:
                <a href="mailto:lopd@somosbuenpuerto.com"
                  >lopd@somosbuenpuerto.com</a
                >.
              </td>
            </tr>
          </table>
        </div>
        <div class="m-4 text-center">
          <b-button @click="$bvModal.hide('modal-legal')">De acuerdo</b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
export default {
  name: "ModalLegalEmpresas",
};
</script>