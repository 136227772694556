<template>
  <div id="empresas" class="section bg-red">
    <div class="w-100">
      <b-container class="section-inner-wide text-left">
        <div class="text-lg ArsenicaBold white mt-0 mb-0">Empresas</div>
        <div class="text-md text-left MontserratRegular white mt-0 mb-4">
          que llegan a buen puerto
        </div>
        <div class="MontserratMedium white text-left mb-4">
          ¿Y si lo que buscas está más cerca de lo que piensas?<br />¡Completa
          este formulario!
        </div>
            <b-form @submit.prevent="submit" method="post">
              <b-form-row>
                <b-col>
                  <b-form-group>
                    <b-form-input
                      class=""
                      v-model="form.first_name"
                      :state="validateState('first_name')"
                      type="text"
                      autocomplete="off"
                      placeholder="Nombre*"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback class="white">
                      Se te ha olvidado darnos tu nombre
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <b-form-input
                      class=""
                      v-model="form.last_name"
                      :state="validateState('last_name')"
                      type="text"
                      autocomplete="off"
                      placeholder="Apellidos*"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback class="white">
                      Se te ha olvidado darnos tus apellidos
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-form-row>
              <b-form-row>
                <b-col>
                  <b-form-group>
                    <b-form-input
                      class=""
                      v-model="form.email"
                      :state="validateState('email')"
                      type="email"
                      autocomplete="off"
                      placeholder="Email*"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback class="white">
                      Se te ha olvidado darnos tu email
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <b-form-input
                      v-model="form.phone"
                      :state="validateState('phone')"
                      type="text"
                      autocomplete="off"
                      placeholder="Teléfono*"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback class="white">
                      Se te ha olvidado darnos tu teléfono
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-form-row>
              <b-form-row>
                <b-col>
                  <b-form-group>
                    <b-form-input
                      class=""
                      v-model="form.company"
                      :state="validateState('company')"
                      type="text"
                      autocomplete="off"
                      placeholder="Empresa*"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback class="white">
                      Se te ha olvidado decirnos cuál es tu empresa
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <b-form-input
                      v-model="form.linkedin"
                      type="text"
                      autocomplete="off"
                      placeholder="Perfil de LinkedIn"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>
              <b-form-group>
                <b-form-textarea
                  v-model="form.comment"
                  rows="3"
                  placeholder="¡Cuéntanos qué estás buscando!"
                >
                </b-form-textarea>
              </b-form-group>
              <p class="white small">*Campos obligatorios</p>
              <b-form-group>
                  <b-form-checkbox
                      v-model="form.accept"
                      name="checkbox-1"
                      :value="true"
                      :unchecked-value="false">
                    <p class="white xsmall">
                      Tus datos serán tratados por SOMOS BUEN PUERTO con la finalidad
                      de contactar contigo para valorar una posible colaboración entre
                      las partes. La base de legitimación para efectuar este
                      tratamiento será el propio consentimiento que nos otorgas al
                      enviar el presente formulario. No cederemos tus datos a terceros
                      sin tu consentimiento, salvo obligación legal. Puedes ejercitar
                      tus derechos de acceso, rectificación, supresión, portabilidad y
                      la limitación u oposición al tratamiento, mediante el envío de
                      un correo electrónico a
                      <a class="white bold" href="mailto:lopd@somosbuenpuerto.com"
                        >lopd@somosbuenpuerto.com</a
                      >. Información adicional sobre el tratamiento de tus datos
                      <a
                        class="white bold pointer"
                        @click="$bvModal.show('modal-legal-empresas')"
                        >aquí</a
                      >.
                    </p>
                  <b-form-invalid-feedback :state="validateState('accept')" class="black">
                      Tienes que aceptar el consentimiento
                  </b-form-invalid-feedback>
                  </b-form-checkbox>
              </b-form-group>

              <div class="d-flex justify-content-center mt-4">
                <b-button
                  type="submit"
                  :disabled="sending"
                  class="btn-white"
                >
                  {{ sending ? "ENVIANDO..." : "ENVIAR" }}
                </b-button>
              </div>
            </b-form>
      </b-container>
    </div>
    <img id="ballena" src="../assets/img/ballena.gif" alt="" />
    <b-modal
      id="modal-company"
      title="BootstrapVue"
      hide-header
      hide-footer
      centered
    >
      <div class="m-8">
        <div class="my-4">
          <p class="my-4 text-center MontserratBold mb-0">
            Tu solicitud a llegado a Buenpuerto.
          </p>
          <p class="text-center mt-0">
            ¡En breve nos pondremos en contacto contigo!
          </p>
        </div>
        <div class="m-4 text-center">
          <b-button @click="$bvModal.hide('modal-company')"
            >De acuerdo</b-button
          >
        </div>
      </div>
    </b-modal>
    <modal-legal-empresas></modal-legal-empresas>
  </div>
</template>

<script>
import ModalLegalEmpresas from "@/components/ModalLegalEmpresas";
import requestApi from "@/api/request.js";
import { validationMixin } from "vuelidate";
import { required, minLength, email, sameAs } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "Candidatos",
  components: {
    ModalLegalEmpresas,
  },
  data() {
    return {
      form: {
        type: "client",
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        company: null,
        linkedin: null,
        comment: null,
        accept: false,
      },
      accepted: false,
      sending: false,
      success: false,
    };
  },
  validations: {
    form: {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      email: {
        required, email
      },
      phone: {
        required,
        minLength: minLength(9),
      },
      company: {
        required,
      },
      accept: {
        sameAs: sameAs(() => true),
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        type: "client",
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        company: null,
        linkedin: null,
        comment: null,
        accept: false,
      };
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    async submit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) return;

      this.sending = true;

      try {
        await requestApi.companyRequest(this.form);
        this.sending = false;
        this.success = true;
        this.sendMails(this.form);
        this.resetForm();
        this.$bvModal.show("modal-company");
      } catch (err) {}
    },
    async sendMails(form) {
      try {
        await requestApi.companyRequestConfirmationMail(form);
      } catch (err) {}
      try {
        await requestApi.companyRequestNotificationMail(form);
      } catch (err) {}
    },
  },
};
</script>