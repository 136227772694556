<template>
    <div id="hero" class="section">
        <div class="hero-container">
            <a href="#candidatos" v-smooth-scroll class="hero-button">
                Talento
                <img src="../assets/svg/wind.svg">
            </a>
            <a href="#candidatos" v-smooth-scroll class="hero-button">
                Profesionales
                <img src="../assets/svg/wind.svg">
            </a>
            <a href="#empresas" v-smooth-scroll class="hero-button">
                Empresas
                <img src="../assets/svg/wind.svg">
            </a>
        </div>
        <img id="barco" src="../assets/img/barco.gif" alt="">
    </div>
</template>
<script>
export default {
    name: 'hero',
    components: {},
    data() {
        return {
        }
    },
    computed: {},
    methods: {}
}
</script>