<template>
    <div id="linkedin" class="section bg-yellow d-flex flex-column px-4 text-left">
    	<div class="flex-grow-1 d-flex align-items-center justify-content-center">
    		<b-container class="section-inner-wide">
                    <div class="text-lg text-left ArsenicaBold black mt-0 mb-4">
                        Síguenos
                    </div>
                    <div class="MontserratMedium black mb-4">
                        <p>Síguenos en redes y no te pierdas nada.</p>
                    </div>
                    <div>
                        <a class="black pointer" href="https://www.linkedin.com/company/buenpuerto/?viewAsMember=true" target="_blank">
                    <img src="../assets/svg/icon-linkedin.svg" width="48">
                </a>
                    </div>
    		</b-container>
    	</div>
        <img id="gaviotas" src="../assets/img/gaviotas.gif" alt="">
    </div>
</template>

<script>

export default {
    name: 'Linkedin',
    components: {
    }
}
</script>