<template>
    <div class="bg-white">
        <div class="small text-center py-4">
            <router-link :to="{ name: 'aviso-legal' }" target="_blank" class="black mx-2">Aviso legal</router-link>
            <router-link :to="{ name: 'privacidad' }" target="_blank" class="black mx-2">Política de privacidad</router-link>
            <router-link :to="{ name: 'cookies' }" target="_blank" class="black mx-2">Política de cookies</router-link>
        </div>
    </div>
</template>
<script>
export default {
    name: 'legal-footer',
    components: {}
}
</script>